import styled from "styled-components"
import BaseCookieConsent from "react-cookie-consent"
import React from "react"
import Link from "./Link"

const style = {
  position: "fixed",
  zIndex: 1030,
  padding: "0.75rem 1.25rem",
  border: "1px solid transparent",
  borderRadius: 0,
  color: "#763f20",
  backgroundColor: "#f9e4d8",
  borderColor: "#f0ba9b",
  fontSize: "0.875rem",
  width: "100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const CookieConsentLink = styled(Link)`
  color: #4e2a15;
  text-decoration: none;
`

const buttonStyle = {
  display: "inline-block",
  boxSizing: "border-box",
  textDecoration: "none",
  padding: ".25rem .5rem",
  cursor: "pointer",
  marginLeft: "1em",
  fontSize: "0.765625rem",
  lineHeight: "1.5",
  borderRadius: "0.2rem",
  color: "#fff",
  backgroundColor: "#e37a3e",
  border: "1px solid #e37a3e",
}

const CookieConsent = () => (
  <BaseCookieConsent
    disableStyles
    style={style}
    buttonStyle={buttonStyle}
    location={"bottom"}
    cookieName={"cookie_consent"}
    buttonText={"Allow cookies"}
    sameSite={"lax"}
    onAccept={() => {
      window.trackGoogleAnalytics()
    }}
  >
    Your experience on this site will be improved by allowing cookies.{" "}
    <CookieConsentLink to={"/legal/cookie-policy"}>
      Learn more
    </CookieConsentLink>
  </BaseCookieConsent>
)

export default CookieConsent
