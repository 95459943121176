import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header/Header"
import Footer from "../Footer"
import "../../styles/styles.css"
import styled from "styled-components"
import CookieConsent from "../CookieConsent"

const LayoutBackground = styled.div``

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LayoutBackground>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer />
      <CookieConsent />
    </LayoutBackground>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
